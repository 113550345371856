import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap, Link } from "."

const Wrapper = styled.div`
  ${({ maxHeight, isOpen, bp = "mobile" }) => css`
    max-height: ${isOpen ? "none" : `${maxHeight}px`};
    overflow: hidden;
    position: relative;

    ${up(bp)} {
      max-height: none;
    }
  `}
`
const Gradient = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 0) 100%
    );
    height: 180px;
    width: 100%;
    position: absolute;
    z-index: 2;
    bottom: 0;
    color: #4052ab;
    font-size: 32px;
    font-weight: 700;
    cursor: pointer;
    padding: 24px;

    ${up("mobile")} {
      display: none;
      /* some reponsivity */
    }
  `}
`

class EllipseContent extends Component {
  state = {
    isOpen: false,
    innerHeight: 0,
  }

  componentDidMount() {
    window.addEventListener("resize", this.checkInnerHeight)
    this.checkInnerHeight()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkInnerHeight)
  }

  checkInnerHeight = () => {
    if (this.childrenRef) {
      this.setState({
        innerHeight: this.childrenRef.offsetHeight,
      })
    }
  }

  open = () => {
    this.setState({ isOpen: true })
  }

  render() {
    const { children, maxHeight, bp } = this.props
    const { isOpen, innerHeight } = this.state

    return (
      <Wrapper maxHeight={maxHeight} isOpen={isOpen} bp={bp}>
        <div
          ref={ref => {
            this.childrenRef = ref
          }}
        >
          {children}
        </div>
        {!isOpen && innerHeight >= maxHeight && (
          <Gradient onClick={this.open}>...</Gradient>
        )}
      </Wrapper>
    )
  }
}

EllipseContent.propTypes = {}

export default EllipseContent
