import React, { useEffect } from "react"

const widgetContent =
  '<div class="portal-by"> <a id="js_portal_by" href="https://www.estheticon.cz" style="text-decoration:none; color:#5d5d5d;"> Podle <span style="color:#139CE8;">Estheticon.cz</span> </a> </div> '

let postscribe

if (typeof window !== "undefined") {
  // this can be imported only in browser
  // otherwise it will break build
  postscribe = require("postscribe")
}

class EstheticonPersonWidget extends React.Component {
  componentDidMount() {
    const { estheticonScriptUrl } = this.props
    const content = `
    <style>
      .ie-reviews {
        display: block !important;
      }
      ul.content-widget-menu__review-list {
        padding-inline-start: 0;
      }
      .content-widget-menu {
        border: 1px solid #dddddddd;
      }
    </style>
    <div id="js_widget" class="ie-reviews">
       <script type="text/javascript" src="${estheticonScriptUrl}"></script>
        ${widgetContent}
    </div>
    `
    if (postscribe) {
      postscribe("#estheticon_widget_wrapper", content)
    }
  }

  render() {
    return (
      <>
        <div id="estheticon_widget_wrapper" />
      </>
    )
  }
}

export default EstheticonPersonWidget
