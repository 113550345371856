// external imports
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"

// helpers
import { up, down, addResponsivity } from "../lib/styles"
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
} from "../lib/helpers"
import { applyLang, applyLangSingle } from "../lib/intl"
import injectIntl from "../lib/intl/injectIntl"
import { enhanceContent } from "../lib/post"

// components
import T from "../lib/intl/T"
import {
  SEO,
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Listing,
  HexaImage,
  WaveImage,
  RoundedImage,
  ContactFormSection,
  EstheticonIframe,
} from "../components"
import EllipseContent from "../components/EllipseContent"
import ProcedureItem from "../components/ProcedureItem"
import EstheticonPersonWidget from "../components/estheticon/EstheticonPersonWidget"

// assets
// import heroImage from '../data/images/logo.png'

// custom styled components
const ScoreIcon = styled.img`
  width: 72px;
  /* height: 80px; */
`

const IframeYoutube = styled.iframe`
  ${({ theme: { colors } }) => css`
    width: 100%;
    height: 230px;
    max-width: 587px;
    overflow: hidden;
    border-radius: 12px;

    ${up("mobile")} {
      height: 330px;
    }
  `}
`

const IframeBig = styled.iframe`
  ${({ theme: { colors }, innerHeight }) => css`
    margin: 0px;
    overflow: hidden;
    width: 100% !important;
    /* outline: 1px solid ${colors.iframeBorder}; */
    margin: 0px;
    overflow: hidden;
    width: 100%;
    visibility: visible;
    opacity: 1;
    height: ${innerHeight}px;
  `}
`

const IframeSmall = styled.iframe`
  ${({ theme: { colors } }) => css`
    margin: 0px;
    overflow: hidden;
    width: 100% !important;
    outline: 1px solid ${colors.iframeBorder};
    visibility: visible;
    opacity: 1;
  `}
`

const Content = styled.div`
  ${({ theme: { colors } }) => css`
    & > .p {
      margin-bottom: 8px;
    }
    & > h2 {
      color: ${colors.blue};
      font-family: 'Signika';
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      margin: 0;
      padding: 0;
      margin-bottom: 16px;
      margin-top: 32px;
      ${up("mobile")} {
        margin-top: 40px;
      }
    }
    & > table > tbody {
      ${down("mobile")} {
        display: flex;
        flex-direction: column;
        margin-top: -16px;
      }

      & > tr {
        height: auto !important;
        ${down("mobile")} {
          display: flex;
          flex-direction: column;
        }
        & > td {
          height: auto !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          ${down("mobile")} {
            width: 100% !important;
          }
          ${up("mobile")} {
            padding: 4px 4px 4px 0;
            vertical-align: top;
          }

          &:first-child {
            color: #8b949e;
            ${down("mobile")} {
              padding-top: 16px;
            }
          }
          &:not(:first-child) {
            ${down("mobile")} {
              font-size: 18px;
              line-height: 30px;
            }
            }
          }
        }
      }
    }
  `}
`

class PersonTemplate extends React.Component {
  state = {
    iframeHeight: 100,
    selectedBeforeAndAfterCategory: null,
  }

  onIframeLoad = () => {
    window.addEventListener("message", this.handleIframeResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleIframeResize)
  }

  handleIframeResize = event => {
    try {
      const data = JSON.parse(event.data)
      if (data.height && data.wid === "999") {
        this.setState({ iframeHeight: data.height })
      }
    } catch (e) {
      console.log("error parsing message data", e)
    }
  }

  render() {
    const {
      intl: { language, t },
      location: { pathname },
    } = this.props

    const { iframeHeight, selectedBeforeAndAfterCategory } = this.state

    const { person, beforeAndAfter } = extractNodesFromDataApplyLangs(
      this.props.data,
      language
    )

    const contentReactElement = enhanceContent({ content: person.content })

    // console.log('person', person)

    const hasVideo = !!person.videoCode

    let videoAndImages = [...(person.images || [])]
    if (hasVideo) {
      // we need offset to correctly position images
      videoAndImages = [null, ...videoAndImages]
    }

    const PHOTOS_BP = 880
    const PHOTO_MOBILE_MAX_WIDTH = "587px"
    const MAIN_ROW_BP = 870

    const showNewEstheticon = person.estheticonScriptUrl && language === "cs"

    let portalVersion = language === "cs" ? 1 : language === "en" ? 2 : 3

    if (language === "cs" && showNewEstheticon) {
      portalVersion = 2
    }

    // rozdělit before and afters dle procedury
    const beforeAndAftersByCategory =
      person?.beforeAndAfters?.reduce((result, baf) => {
        const { procedure } = baf
        if (!procedure) return result
        if (!result[procedure._id]) {
          result[procedure._id] = {
            procedure,
            beforeAndAfters: [],
          }
        }
        result[procedure._id].beforeAndAfters.push(baf)

        return result
      }, {}) || {}

    const isBeforeAndAfterVisible =
      Object.keys(beforeAndAftersByCategory || {}).length > 0

    return (
      <>
        <SEO
          title={`${person.name} · ${t("people - meta title")}`}
          pathname={pathname}
          description={person.metaDesc}
        />
        <Gap.Top />
        <Col alignSelf="center" maxWidth="1040px" width="100%">
          <Row responsive bp={870} mobileFlexDirection="column-reverse">
            <Col maxWidth="357px" width="100%">
              <RH showAfter={MAIN_ROW_BP}>
                <HexaImage image={person.photo.filename.image.gatsbyImageData} />
              </RH>
              <Gap gap="40px" />
              {showNewEstheticon && (
                <Col width="100%" alignItems="center">
                  <EstheticonPersonWidget
                    estheticonScriptUrl={person.estheticonScriptUrl}
                  />
                  <Gap gap="40px" />
                </Col>
              )}

              {person.estheticonId && (
                <>
                  <div>
                    <IframeSmall
                      frameborder="0"
                      frameBorder="0"
                      src={`https://www.estheticon.com/forms/custom-widget?outline=outline: 0px solid rgb(232, 235, 241);&content=default&wid=111&client=${person.estheticonId}&portal-version=${portalVersion}&version=2&abranch=1&type=large&count=3`}
                      height="348"
                    />
                  </div>
                  <Gap gap="80px" mobileGap="40px" />
                </>
              )}
              {person.highScore && (
                <Col
                  alignItems="flex-start"
                  mobilePadding="0 0 0 36px"
                  padding="0"
                >
                  <WaveImage />
                  <Gap gap="24px" />
                  <Title.SmallerTitle>
                    <T>Co má za sebou</T>
                  </Title.SmallerTitle>
                  {person.highScore.map(score => (
                    <>
                      <Gap gap="32px" />
                      <Row alignItems="center">
                        <ScoreIcon
                          src={require(`../data/images/score-icons/${score.image}`)}
                        />
                        <Gap gap="24px" />
                        <Col>
                          <Title.MediumTitle lineHeight="30px">
                            {score.howMuch}
                          </Title.MediumTitle>
                          <Text>{score.what}</Text>
                        </Col>
                      </Row>
                    </>
                  ))}
                </Col>
              )}
            </Col>
            <Gap.Fluid min="32px" max="98px" showAfter={MAIN_ROW_BP} />
            <Col maxWidth="578px" width="100%">
              <RH hideAfter={MAIN_ROW_BP}>
                <Col maxWidth="240px" width="100%">
                  <HexaImage image={person.photo.filename.image.gatsbyImageData} />
                  <Gap gap="24px" />
                </Col>
              </RH>
              <Title.PageTitle>{person.name}</Title.PageTitle>
              <EllipseContent maxHeight={1860}>
                <Text responsive>
                  {/* <Content dangerouslySetInnerHTML={{ __html: person.content }} /> */}
                  <Content>{contentReactElement}</Content>
                </Text>
              </EllipseContent>
            </Col>
          </Row>
          <Gap gap="80px" />
          {videoAndImages.length > 0 && (
            <Row
              maxWidth="980px"
              width="100%"
              alignSelf="center"
              alignItems="center"
              responsive
              bp={PHOTOS_BP}
              mobileAlignItems="flex-start"
            >
              <Col
                maxWidth="587px"
                width="100%"
                alignItems="flex-end"
                bp={PHOTOS_BP}
                mobileAlignItems="stretch"
              >
                {hasVideo ? (
                  <Col maxWidth="587px" width="100%">
                    <IframeYoutube
                      frameBorder="0"
                      frameborder="0"
                      width="587"
                      height="330"
                      src={`https://www.youtube.com/embed/${person.videoCode}`}
                    />
                  </Col>
                ) : (
                  <RoundedImage
                    image={videoAndImages[0].image.filename.image.gatsbyImageData}
                  />
                )}
                {videoAndImages[1] && (
                  <>
                    <Gap gap="24px" mobileGap="16px" bp={PHOTOS_BP} />
                    <Col
                      maxWidth="489px"
                      width="100%"
                      mobileMaxWidth={PHOTO_MOBILE_MAX_WIDTH}
                      bp={PHOTOS_BP}
                    >
                      <RoundedImage
                        image={videoAndImages[1].image.filename.image.gatsbyImageData}
                      />
                    </Col>
                  </>
                )}
              </Col>
              <Gap gap="34px" mobileGap="16px" bp={PHOTOS_BP} />
              {videoAndImages[2] && (
                <Col
                  maxWidth="358px"
                  width="100%"
                  mobileMaxWidth={PHOTO_MOBILE_MAX_WIDTH}
                  bp={PHOTOS_BP}
                >
                  <RoundedImage
                    image={videoAndImages[2].image.filename.image.gatsbyImageData}
                  />
                </Col>
              )}
            </Row>
          )}
        </Col>

        <Gap gap="80px" mobileGap="48px" />

        {isBeforeAndAfterVisible && (
          <Col
            width="100%"
            maxWidth="1096px"
            alignSelf="center"
            alignItems="flex-start"
          >
            <WaveImage />
            <Gap gap="32px" mobileGap="24px" />
            <Title.BigTitle>
              <T>Fotografie před a po</T>
            </Title.BigTitle>
            <Gap gap="32px" mobileGap="24px" />
            {Object.keys(beforeAndAftersByCategory || {}).map(categoryId => (
              <Text
                onClick={() =>
                  this.setState({ selectedBeforeAndAfterCategory: categoryId })
                }
                style={{ cursor: "pointer", textDecoration: "underline" }}
                key={categoryId}
              >
                {beforeAndAftersByCategory[categoryId].procedure.name}
              </Text>
            ))}
            <Gap gap="32px" mobileGap="24px" />
            {beforeAndAftersByCategory[
              selectedBeforeAndAfterCategory
            ]?.beforeAndAfters?.map(baf => (
              <Col key={baf._id} width="600px" maxWidth="100%">
                <RoundedImage image={baf.image?.image.filename.image.gatsbyImageData} />
                {baf.description && (
                  <>
                    <Gap gap="16px" />
                    <Text>{baf.description}</Text>
                  </>
                )}
                <Gap gap="16px" />
              </Col>
            ))}
            <Gap gap="80px" mobileGap="48px" />
          </Col>
        )}

        {person.procedures && person.procedures.length > 0 && (
          <Col
            width="100%"
            maxWidth="1096px"
            alignSelf="center"
            alignItems="flex-start"
          >
            <WaveImage />
            <Gap gap="32px" mobileGap="24px" />
            <Title.BigTitle>
              <T>Jaké zákroky provádí</T>
            </Title.BigTitle>
            <Gap gap="32px" mobileGap="24px" />
            <Listing maxWidth="1096px" gap="77px">
              {person.procedures.map(p => (
                <ProcedureItem innerKey={p._id} key={p._id} procedure={p} />
              ))}
            </Listing>
          </Col>
        )}

        <Gap gap="80px" mobileGap="48px" />

        {person.estheticonId && (
          <>
            <Col
              maxWidth="820px"
              alignSelf="center"
              width="100%"
              alignItems="flex-start"
            >
              <Row>
                <Gap gap="24px" mobileGap="24px" />
                <Col alignItems="flex-start">
                  <WaveImage />
                  <Gap gap="32px" mobileGap="24px" />
                  <Title.BigTitle>
                    <T>Recenze na</T> {person.name}
                  </Title.BigTitle>
                  <Gap gap="24px" mobileGap="24px" />
                </Col>
              </Row>

              <EstheticonIframe language={language} eid={person.estheticonId} />
            </Col>
          </>
        )}
        <Gap gap="80px" mobileGap="48px" />

        <ContactFormSection />
      </>
    )
  }
}

export default injectIntl(PersonTemplate)

export const pageQuery = graphql`
  query ($id: String!) {
    person: people(id: { eq: $id }) {
      ...PersonFragment
    }

    site {
      siteMetadata {
        siteUrl: url
      }
    }
  }
`
